import React from 'react'
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import desImg1 from "../assets/img/jersey.png";
import desImg2 from "../assets/img/covid.png";
import desImg3 from "../assets/img/stellar.png";
import desImg4 from "../assets/img/coffee.png";
import desImg5 from "../assets/img/shampoo.png";
import desImg6 from "../assets/img/show.png";
import colorSharp2 from "../assets/img/color-sharp2.png";


export const Projects = () => {

  const projects = [
    {
      title: <a href="https://www.behance.net/gallery/164199869/UIUX-Design-LAKAPATI-Detect-and-Map-Crop-Disease" target="_blank" rel="noreferrer">LAKAPATI</a>,
      description: "Revolutionize Crop Health: Detect and Map Crop Disease with AI-powered Mobile App!",
      imgUrl: projImg1,
    },
    {
      title: <a href="https://www.behance.net/gallery/164468547/Dark-UI-Expense-and-Budget-Tracker" target="_blank" rel="noreferrer">Dark UI</a>,
      description: "Expense and Budget Tracker",
      imgUrl: projImg2,
    },
    {
      title: <a href="https://www.behance.net/gallery/164461429/UIUX-Design-WALLET-WIZARD-Budget-Expense-Tracker" target="_blank" rel="noreferrer">WALLET WIZARD</a>,
      description: "Take Control of Your Finances on the Go with Our Mobile App!",
      imgUrl: projImg3,
    },
  ];

    const designs = [
      {
        title: "Jersey/Sports Uniform",
        description: "Basketball/Volleyball Uniform Design",
        imgUrl: desImg1,
      },
      {
        title: "Covid-19 Infographics",
        description: "Corona Virus Symptoms & Preventions Infographics Design",
        imgUrl: desImg2,
      },
      {
        title: "Stellar Co. Logo",
        description: "Logo and Metal Mockup Design",
        imgUrl: desImg3,
      },
      {
        title: "Packaging Design",
        description: "Coffee, Packaging Mockup and Design",
        imgUrl: desImg4,
      },
      {
        title: "Shampoo Design and Mockup",
        description: "Love Salon Shampoo & Conditioner Bottle Logo and Mockup Design",
        imgUrl: desImg5,
      },
      {
        title: "Pet Show Design",
        description: "Tarpaulin and Flyer Design for a Pet Show",
        imgUrl: desImg6,
      },
    ];


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
              <div>
                <h2 className= 'animation' data-aos="fade-down" data-aos-delay="100">Projects</h2>
                <p className= 'animation' data-aos="fade-down" data-aos-delay="200">Accumulated projects acquired by the skills aforementioned, a diverse portfolio of success stories, ready to inspire your next big thing.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item className= 'animation' data-aos="fade-right" data-aos-delay="300">
                      <Nav.Link eventKey="first">UI/UX Design</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className= 'animation' data-aos="fade-right" data-aos-delay="600">
                      <Nav.Link eventKey="second">Graphic Design</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className= 'animation' data-aos="fade-right" data-aos-delay="900">
                      <Nav.Link eventKey="third">Certificates/Courses</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className= 'animation' data-aos="fade-up" data-aos-delay="900">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          designs.map((design, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...design}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <ul className='project'>
                        <li><a href="https://www.udemy.com/certificate/UC-7438e992-1b68-412c-b53e-2bbc1b7a23df/" target="_blank" rel="noreferrer">Learn Figma-UI/UX Design Essential Training (Udemy)</a></li>
                        <li><a href="https://www.futurelearn.com/certificates/pr2tstf" target="_blank" rel="noreferrer">Digital Skills: User Experience (Accenture)</a></li>
                        <li><a href="https://www.linkedin.com/learning/certificates/61701c65f08fb15749a4f257b7757a50150230707e77ee94980fbe69d4f4bebe" target="_blank" rel="noreferrer">UX Deep Dive: Mapping</a></li>
                        <li><a href="https://www.linkedin.com/learning/certificates/baf9459e8e1f5c3cbb84aa29ec84493eb50fc3baeb9fd4a2903ecb64356a29de" target="_blank" rel="noreferrer">UX Design: Ideation</a></li>
                        <li><a href="https://www.linkedin.com/learning/certificates/046c555f213afe3e2cbed3b631c5d6ec4a347857c44efed62acd644cae8ed78b" target="_blank" rel="noreferrer">UX Design: Implementation Planning</a></li>
                        <li><a href="https://olympus.mygreatlearning.com/courses/55928/certificate" target="_blank" rel="noreferrer">UI/UX for Beginners (Great Learning)</a></li>
                        <li><a href="https://www.linkedin.com/learning/certificates/872086fabbecd4648873780d4840b557792285c7ff4d99cc2ee0324221227dc7" target="_blank" rel="noreferrer">UX DesignOps: Working with Developers</a></li>
                        <li><a href="https://www.linkedin.com/learning/certificates/7bec57a888805299e276fab1fe9388f6fc3f707dc1399b07cf0e85434efc10c4" target="_blank" rel="noreferrer">UX Design: Analyzing User Data</a></li>
                        <li><a href="https://www.linkedin.com/learning/certificates/e900a06857d647f453dd51f6fba3e0752708e980507944440654a77da41a295b" target="_blank" rel="noreferrer">UX Design: Prototyping</a></li>
                        <li><a href="https://codewithmosh.com/courses/417695/certificate" target="_blank" rel="noreferrer">Complete Python Course</a></li>
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}