import React from 'react'
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";


export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});


  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
                <img className='animation' data-aos="zoom-in" data-aos-delay="400" src={contactImg} alt="Contact Us"/>
          </Col>
          <Col size={12} md={6}>
              <div className='animation' data-aos="fade-left" data-aos-delay="500">
                <h2>Get In Touch</h2>
                <form action="https://getform.io/f/6fba5a74-5dc2-43be-a78d-e02f77eb9087" method='POST'>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="name" placeholder="First Name"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="last name" placeholder="Last Name"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="email" placeholder="Email Address"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="phone number" placeholder="Phone No."/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea type="text" name="message" rows="6" placeholder="Message"></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                  </Row>
                </form>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}