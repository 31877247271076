import React from 'react'
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import avatarImg from "../assets/img/avatar.png";


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "UI/UX Designer", "Graphic Designer" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={8}>
                <span className="tagline" data-aos="fade-down" data-aos-delay="400">Welcome to my Portfolio</span>
                <h1 className='animation' data-aos="fade-down" data-aos-delay="550">{`Hi! I'm Benaiah Navarro a`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "UI/UX Designer", "Graphic Designer" ]'><span className="wrap">{text}</span></span></h1>
                  <p className='animation' data-aos="fade-down" data-aos-delay="700">I believe that genuine design should aesthetically and functionally address and solve the problem. I love learning different approaches and philosophies of each discipline. I've gained some skills/experiences while working in different places during my vacation and free time, and also as a freelancer. I'm eager to apply these experiences to help your company. And I would like to hone my skills further and continue to learn more knowledge that can help me in my career.
                    </p>
                  <a href="mailto:benaiahnavarro@gmail.com" target="_blank" rel="noreferrer">
                    <button className='animation' data-aos="fade-down" data-aos-delay="850">Let’s Connect <ArrowRightCircle size={25} /></button>
                  </a>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div>
              <img className='animation' data-aos="fade-down" data-aos-delay="500" src={avatarImg}/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}